import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Hero from '../components/shared/Hero';
import Cta from '../components/shared/Cta';
import SEO from '../components/SEO';
import Form from '../components/shared/Form';

const StyledRoot = styled.section`
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
  margin: auto;
`;

const StyledVideoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;

  div.content {
    width: 50%;

    @media (max-width: 1200px) {
      width: 75%;
    }

    @media (max-width: 600px) {
      width: 100%;
    }

    video {
      width: 100%;
      box-shadow: ${(props) => props.theme.effects.boxShadow};
      margin-bottom: 50px;

      @media (max-width: 500px) {
        width: 100%;
      }
    }
  }

  div.form-wrapper {
    width: 40%;

    @media (max-width: 1200px) {
      width: 75%;
    }

    @media (max-width: 600px) {
      width: 100%;
    }
  }
`;

export default function VideoPageTemplate({ data }) {
  const {
    metaDescription,
    metaTitle,
    pageTitle,
    video,
    videoDescription,
    videoPoster,
  } = data.page;

  return (
    <>
      <SEO description={metaDescription} title={metaTitle} />
      <Hero headline={pageTitle} short />
      <StyledRoot>
        <StyledVideoWrapper>
          <div className='content'>
            <video controls poster={videoPoster.file.url}>
              <source src={video.file.url} type='video/webm'></source>
              <source src={video.file.url} type='video/mp4'></source>
            </video>
            <div>
              {documentToReactComponents(JSON.parse(videoDescription.raw))}
            </div>
          </div>
          <div className='form-wrapper'>
            <Form
              formName='contact-page'
              noPaddingTop
              buttonText='Get Free Consultation'
            />
          </div>
        </StyledVideoWrapper>
      </StyledRoot>
      <Cta
        ctaSupertext='Free Consultation'
        ctaHeadline='Call now to speak with one of our professional engineers or consultants.'
        phoneNumber='8009429255'
        email='sales@concretefence.com'
        altText='tbd'
      />
    </>
  );
}

export const query = graphql`
  query ($slug: String!) {
    page: contentfulVideoPageTemplate(slug: { eq: $slug }) {
      metaDescription
      metaTitle
      pageTitle
      slug
      video {
        file {
          url
        }
        title
      }
      videoDescription {
        raw
      }
      videoPoster {
        file {
          url
        }
      }
    }
  }
`;
