// external Dependencies
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// gatsby Dependencies
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

// internal Dependencies
import Button from './Button';

const StyledImageContainer = styled.div`
  height: ${(props) => (props.short ? '25vh' : '50vh')};
  min-height: ${(props) => (props.short ? '300px' : '500px')};
  width: 100%;
  display: grid;
  position: relative;

  @media (min-width: 1400px) {
    min-height: 650px;
  }

  h1 {
    color: ${(props) => props.theme.colors.white};
    margin: ${(props) => (props.buttonText ? '0 auto 3rem auto' : '0 auto')};
  }

  h5 {
    color: ${(props) => props.theme.colors.white};
  }

  @media (max-width: 800px) {
    min-height: 350px;
    height: 500px;
  }

  @media (max-width: 800px) {
    height: 400px;
  }
`;

const StyledBackgroundSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => (props.short ? '25vh' : '50vh')};
  min-height: ${(props) => (props.short ? '300px' : '500px')};
  width: 100%;
  background-color: ${(props) => props.theme.colors.ecru};
  h1 {
    color: ${(props) => props.theme.colors.blue};
    margin: ${(props) => (props.buttonText ? '0 auto 3rem auto' : '0 auto')};
  }

  h5 {
    color: ${(props) => props.theme.colors.gold};
  }

  @media (max-width: 800px) {
    min-height: unset;
    height: 350px;
  }

  @media (max-width: 600px) {
    min-height: unset;
    height: 200px;
  }
`;

const StyledTextWrapper = styled.div`
  width: 100%;
  text-align: center;

  h1,
  h5 {
    max-width: 75%;
    margin: 1rem auto;

    @media (max-width: 600px) {
      max-width: 90%;
    }
  }

  h5 {
    text-transform: uppercase;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    letter-spacing: 1px;
  }

  h5:before,
  h5:after {
    content: '';
    border-top: 4px solid;
    margin: 0 10px;
    flex: 0 0 40px;
  }

  button {
    margin: auto;
  }
`;

const StyledOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${(props) => props.theme.colors.blue};
  opacity: 0.3;
  z-index: 1;
`;

const StyledHeroContentContainer = styled.div`
  /* By using the same grid area for both, they are stacked on top of each other */
  grid-area: 1/1;
  position: relative;
  /* This centers the other elements inside the hero component */
  place-items: center;
  display: grid;
`;

// component definition
const Hero = ({
  short,
  bkgImage,
  superText,
  headline,
  buttonText,
  buttonLink,
  buttonColor,
  body,
  bodyTwo,
  hrEmail,
  ...other
}) => {
  const backgroundImage = getImage(bkgImage);

  const content = (
    <StyledTextWrapper>
      {superText && <h5>{superText}</h5>}
      <h1>{headline}</h1>
      {body && hrEmail && (
        <h4 style={{ paddingLeft: '25px', paddingRight: '25px', fontSize: '20px' }}>
          <span style={{ color: '#282a73' }}>{body}</span>
          {bodyTwo}
          <a href={`mailto:${hrEmail}`}>{hrEmail}</a>.
        </h4>
      ) || body && (
        <p style={{ paddingLeft: '25px', paddingRight: '25px' }}>{body}</p>
      )}
      {buttonText && (
        <Button buttonColor={buttonColor} link={buttonLink}>
          {buttonText}
        </Button>
      )}
    </StyledTextWrapper>
  );

  return (
    <>
      {bkgImage ? (
        <StyledImageContainer>
          <StyledOverlay />
          <GatsbyImage
            aspectRatio={3 / 1}
            image={backgroundImage}
            loading='eager'
            alt=''
            {...other}
            style={{
              gridArea: '1/1',
            }}
          />
          <StyledHeroContentContainer>{content}</StyledHeroContentContainer>
        </StyledImageContainer>
      ) : (
        <StyledBackgroundSection short={short} {...other}>
          {content}
        </StyledBackgroundSection>
      )}
    </>
  );
};

Hero.propTypes = {
  superText: PropTypes.string,
  headline: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  short: PropTypes.bool,
  body: PropTypes.string,
};
Hero.defaultProps = {
  headline: '',
  short: false,
  superText: '',
  buttonText: '',
  buttonLink: '',
  body: '',
};

export default Hero;
